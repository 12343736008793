import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import React, { useRef, useState } from "react";
import "./DesktopSelectedSubjects.css";
import Modal from "../Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  selectInscribedSubjects,
  selectLoadingRequestInTransit,
  selectSelectedOfferedSubjects,
  selectUnsubProcessSectionId,
  setunsubProcessSectionId,
} from "../../reduxSlices/subjectsSlice";
import useUnsubscribeSection from "../../hooks/useUnsubscribeSection";
import { updateDetailSubject } from "../../reduxSlices/detailSubjectSlice";
import { IInscribedSubject, IInscribedSubjectSection } from "../../types";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";
import { selectConfirmationsFlag } from "../../reduxSlices/parametersSlice";
import EmptyCourses from "../EmptyCourses/EmptyCourses";
import { LiaSpinnerSolid } from "react-icons/lia";

interface ISelectedSubject {
  title: string;
  sectionId: number;
  subjectId: number;
  active: boolean;
}

interface ISubjectToUnsub {
  sectionId: number;
  section: string;
}

const DesktopSelectedSubjects = () => {
  const [open, setOpen] = useState(false);
  const loadingRequestInTransit = useSelector(selectLoadingRequestInTransit);
  const [subjectToUnsub, setSubjectToUnsub] = useState<ISubjectToUnsub>();
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const offerSubjectAlmostConfirmed = useSelector(selectSelectedOfferedSubjects);
  const confirmationsEnabled = useSelector(selectConfirmationsFlag);
  const unsubProcessSectionId = useSelector(selectUnsubProcessSectionId);

  const unsubscribeSection = useUnsubscribeSection();

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleSetDetailSubject = (id: number) => {
    const sections =
      inscribedSubjects?.filter((subject: IInscribedSubject) => subject.idCurso === id)[0].sections || [];
    if (sections?.length) {
      dispatch(updateDetailSubject({ sections }));
    }
  };

  const handleUnsuscribeSection = async (idCurso: number) => {
    const result = await unsubscribeSection(idCurso);
  };

  const handleTrashSubject = async (subject: IInscribedSubject) => {
    setSubjectToUnsub({
      sectionId: subject.idCurso,
      section: subject.title,
    });
  };

  const renderSelectedSubjects = (subjects: IInscribedSubject[]) => {
    return subjects.map((s, index) => {      
      const isLastItem = subjects.length === index + 1;
      return (
        <div className="desktop-item-wrapper" key={s.title + index}>
          <div className="desktop-inscribed-subject-item">
            <div className="desktop-selected-subjects-item-left">
              <h2 className="desktop-selected-subject-item-left-title">{s.title}</h2>
              <p>{s.sections[0].subjectData.profesor ? s.sections[0].subjectData.profesor : "---"}</p>
              {/* <p>{JSON.stringify(s)}</p> */}
            </div>
            <div className="desktop-selected-subject-item-right">
              <button
                onClick={() => handleTrashSubject(s)}
                className="desktop-btn-unsuscribe-subject"
                disabled={unsubProcessSectionId === Number(s.idCurso)}
              >
                {unsubProcessSectionId === Number(s.idCurso) ? (
                  <>
                    <LiaSpinnerSolid className="spinner-animation" /> Procesando
                  </>
                ) : (
                  <>
                    <SlIcon name="dash-lg" />
                    Desinscribirme
                  </>
                )}
              </button>
            </div>
          </div>
          {!isLastItem && <SlDivider />}
        </div>
      );
    });
  };

  return (
    <div className="desktop-inscribed-subjects" ref={containerRef}>
      <div className="desktop-inscribed-subjects-list">
        {inscribedSubjects && inscribedSubjects?.length > 0 ? (
          renderSelectedSubjects(inscribedSubjects)
        ) : (
          <EmptyCourses />
        )}
      </div>
      {subjectToUnsub && subjectToUnsub.sectionId && (
        <Modal
          open={subjectToUnsub.sectionId ? true : false}
          onClose={() => setOpen(false)}
          message={`¿Estás seguro de que deseas eliminar ${subjectToUnsub.section} de tu oferta académica?`}
          okButton={{
            label: "Continuar",
            buttonFn: () => {
              dispatch(setunsubProcessSectionId(subjectToUnsub.sectionId));
              handleUnsuscribeSection(subjectToUnsub.sectionId);
              setSubjectToUnsub(undefined);
            },
          }}
          closeButton={{
            label: "Cancelar",
            buttonFn: () => setSubjectToUnsub(undefined),
          }}
        />
      )}
    </div>
  );
};

export default DesktopSelectedSubjects;
