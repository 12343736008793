import { useDispatch } from "react-redux";
import { desinscribirDeCurso } from "../Api/inscription";
import { useToastError } from "./useToast";

const useUnsubscribeSection = () => {
  const showError = useToastError();

  const handleBajaCurso = async (sectionId: number): Promise<boolean> => {
    try {
      const response = await desinscribirDeCurso(sectionId);
      if (response.status === 200 && response.data.resultado >= 0) {
        console.log("CLOUD validations: OK");
        return true;
      } else {
        showError(response.data.mensaje);
        throw new Error("Request failed");
      }
    } catch (error) {
      return false;
    }
  };
  return handleBajaCurso;
};

export default useUnsubscribeSection;
