import axios from "axios";

export const getPrioridad = async () => {
  const { data }: { data: { tienePrioridad: boolean; mensajePrioridad: string } } = await axios.post(
    `/api/refreshPrioridad?r=${Math.random().toString()}`,
    {}
  );
  return data;
};

export const updatePrioridad = async (prioridad:boolean) => {
  const { data }: { data: { tienePrioridad: boolean; mensajePrioridad: string } } = await axios.post(
    `/api/prioridad?r=${Math.random().toString()}`,
    {prioridad}
  );
  return data;
};
