import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { getPrioridadBuilder } from "../reduxAsyncThunks/prioridad";

export interface StudentState {
  id: number;
  email: string;
  completeName: { name: string; lastName: string };
  studentInitState: number;
  bloqueo: { mensaje: string; subMensaje: string; activo: boolean; contactoBloqueo: string };
  prioridad: boolean | null;
  mensajePrioridad: string;
  mostrarHorarios: boolean;
  carreraActual: string;
  token: string;
}

const initialState: StudentState = {
  id: 0,
  email: "",
  completeName: { name: "", lastName: "" },
  studentInitState: 0,
  bloqueo: { mensaje: "", subMensaje: "", activo: false, contactoBloqueo: "" },
  prioridad: null,
  mensajePrioridad: "",
  mostrarHorarios: true,
  carreraActual: "",
  token: "",
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    updateStudentData: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.completeName = action.payload.completeName;
    },
    setStudentInicializado: (state, action) => {
      state.studentInitState = action.payload;
    },
    setStudentBloqueo: (state, action) => {
      state.bloqueo.mensaje = action.payload.mensaje;
      state.bloqueo.subMensaje = action.payload.subMensaje;
      state.bloqueo.activo = action.payload.activo;
    },
    setStudentPrioridad: (state, action) => {
      state.prioridad = action.payload.prioridad;
      state.mensajePrioridad = action.payload.mensajePrioridad;
    },
    setStudentMostrarHorarios: (state, { payload }) => {
      state.mostrarHorarios = payload;
    },
    setCarrearActual: (state, { payload }) => {
      state.carreraActual = payload.trim();
    },
    setToken: (state, { payload }) => {
      state.token = payload;
    },
  },
  extraReducers(builder) {
    getPrioridadBuilder(builder);
  },
});

export const selectStudent = (state: RootState) => state.student;
export const selectToken = (state: RootState) => state.student.token;

export const {
  updateStudentData,
  setStudentInicializado,
  setStudentBloqueo,
  setStudentPrioridad,
  setStudentMostrarHorarios,
  setCarrearActual,
  setToken,
} = studentSlice.actions;

export default studentSlice.reducer;
